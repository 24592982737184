@import '../../scss/mixins';
@import '../../scss//variables';


.promos {
    @include flexCenter;
    color: $primary-text-color;
    background-color: #EFEBE5;
    width: 100%;
    height: 100%;
    margin-top: 80px;
    padding-top: 80px;
    padding-bottom: 80px;

    h1 {
        text-align: center;
    }

    h2 {
        text-align: center;
        font-size: 2em;
    }
}

.promos-container {
    height: 100%;
    width: 80%;
}



.promos-container-inner {
    display: flex;
    justify-content: center;
    margin-top: 80px;
}



.promo-card {
    width: 400px;
    min-height: 180px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;



}

.promo-card-description {
    margin-top: 30px;
    border: 1px solid $primary-text-color;
    padding: 30px;
    width: 100%;
    min-height: 150px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;


    * {
        word-wrap: break-word;
    }



    .promo-title {
        margin-bottom: 30px;
        font-size: 1.2em;
    }


}



@media (max-width:1000px) {
    .promo-card-description {
        padding: 15px;
    }
}


@media (max-width:800px) {
    .promos {
        margin-top: 0;
        padding-top: 60px;
        padding-bottom: 0;
    }

    .promos-container-inner {
        flex-direction: column;
        align-items: center;
        margin-top: 60px;

        .promo-card {
            margin-right: 0;
            margin-bottom: 60px;
        }
    }
}



@media (min-width:350px) and (max-width:499px) {
    .promo-card{
        width: 300px;
    }
}



@media (max-width:349px) {
    .promo-card{
        width: 280px;
    }
}