.front-page {
  font-family: "Noto Serif JP", serif;
  color: #F4F1ED;
  display: flex;
  justify-content: center;
  height: 100%;
}
.front-page .heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  height: 100%;
  line-height: 65px;
}
.front-page h1 {
  font-size: 4em;
  font-weight: 500;
}
.front-page h2 {
  font-size: 2em;
  font-weight: 400;
}
.front-page h3 {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.25em;
  margin-bottom: 25px;
}
.front-page .front-page-btn {
  font-family: "Noto Sans JP", sans-serif;
  z-index: 3;
  background-color: transparent;
  border: 2px solid white;
  color: #F4F1ED;
  padding: 8px 40px;
  position: absolute;
  top: 70%;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.front-page .front-page-btn:hover {
  background-color: white;
  color: black;
}

.front-page-video-container {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  display: block;
  background-image: url("../../content/front-page-video.mp4");
}/*# sourceMappingURL=FrontPage.css.map */