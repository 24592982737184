.promos {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #623E2A;
  background-color: #EFEBE5;
  width: 100%;
  height: 100%;
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
}
.promos h1 {
  text-align: center;
}
.promos h2 {
  text-align: center;
  font-size: 2em;
}

.promos-container {
  height: 100%;
  width: 80%;
}

.promos-container-inner {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.promo-card {
  width: 400px;
  min-height: 180px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}

.promo-card-description {
  margin-top: 30px;
  border: 1px solid #623E2A;
  padding: 30px;
  width: 100%;
  min-height: 150px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.promo-card-description * {
  word-wrap: break-word;
}
.promo-card-description .promo-title {
  margin-bottom: 30px;
  font-size: 1.2em;
}

@media (max-width: 1000px) {
  .promo-card-description {
    padding: 15px;
  }
}
@media (max-width: 800px) {
  .promos {
    margin-top: 0;
    padding-top: 60px;
    padding-bottom: 0;
  }
  .promos-container-inner {
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
  }
  .promos-container-inner .promo-card {
    margin-right: 0;
    margin-bottom: 60px;
  }
}
@media (min-width: 350px) and (max-width: 499px) {
  .promo-card {
    width: 300px;
  }
}
@media (max-width: 349px) {
  .promo-card {
    width: 280px;
  }
}/*# sourceMappingURL=Promos.css.map */