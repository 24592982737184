@import '../../scss/mixins';
@import '../../scss//variables';


.footer {
    background-color: #623E2A;
    width: 100%;
    color: $secondary-text-color;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;

    

}

.footer-container {
    width: 80%;
}

.footer-container-inner {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    *{
        font-size: 0.9rem;
    }
}

.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 150px;
    width: 400px;
    margin-right: 30px;
    padding: 30px;
    border: 1px solid $secondary-text-color;

    h4 {
        margin-bottom: 30px;
    }
}

.contact-container-inner {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

}

.contact-icon {
    margin-right: 20px;
}

.social {
    width: 50px;
    height: 50px;
    padding: 10px;
    border: 1px solid $secondary-text-color;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.1s ease-in-out;


    &:hover {
        background-color: darken($primary-text-color, 10%);
        transform: scale(1.1);

    }
}

.social-btn {
    color: $secondary-text-color;
    background-color: $primary-text-color;
    border: 1px solid $secondary-text-color;
    padding: 15px;
    width: 100%;
    border-radius: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: darken($primary-text-color, 10%);
    }
}

.contact-address-container {
    display: flex;
    flex-direction: column;
}

#work-hours-container {
    flex-direction: column;
    align-items: start;
}



@media (max-width:1200px) {

 
    .contact-icon {
        margin-right: 15px;
    }

    .social {
        width: 45px;
        height: 45px;
        margin-right: 8px;
    }

    .social-btn {
        padding: 10px;
    }
}


@media (max-width:1050px) {

    .social{
        width: 38px;
        height: 38px;
        margin-right: 5px;
    }

    .footer-container-inner {
     
        *{
            font-size: 0.8rem;
        }
    }
}

@media (max-width:1000px) {
    .contact-container {
        padding: 15px;
    }
}




@media (max-width:800px) {
    .footer{
        padding-top: 60px;
        padding-bottom: 60px;


        h1{
            text-align: center;
        }
    }

    .footer-container-inner {
        flex-direction: column;
        align-items: center;
    }

    .contact-container{
        margin-right: 0;
        margin-bottom: 30px;
    }

    .social-btn{
        margin-top: 30px;
    }
}





@media (min-width:350px) and (max-width:499px) {
 

    .contact-container{
        margin-right: 0;
        margin-bottom: 30px;
        width: 300px;
    }

   
}



@media (max-width:349px) {
    .contact-container{
        width: 280px;
    }
}