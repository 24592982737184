@import '../../scss/mixins';
@import '../../scss/variables';


.front-page {

    font-family: 'Noto Serif JP', serif;
    color: $secondary-text-color;
    display: flex;
    justify-content: center;
    height: 100%;

    .heading-container {
        @include flexCenter;
        flex-direction: column;
        position: absolute;
        height: 100%;
        line-height: 65px;
    }

    h1 {
        font-size: 4em;
        font-weight: 500;
    }

    h2 {
        font-size: 2em;
        font-weight: 400;
    }

    h3 {
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 1.25em;
        margin-bottom: 25px;
    }


    .front-page-btn {
        font-family: 'Noto Sans JP', sans-serif;
        z-index: 3;
        background-color: transparent;
        border: 2px solid white;
        color: $secondary-text-color;
        padding: 8px 40px;
        position: absolute;
        top: 70%;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.25s ease-in-out;


        &:hover {
            background-color: white;
            color: black;
        }


    }
}



.front-page-video-container{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    display: block;
    background-image: url('../../content/front-page-video.mp4');
}