@import '../../scss/mixins';
@import '../../scss/variables';



.home {
    @include flexCenter;
    width: 100%;
    height: 100vh;
    padding-top: 80px;
    color: $primary-text-color;
}

.home-heading-container {
    transform: translateX(-50%);
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;


    h2 {
        font-size: 2em;
    }

    p {
        font-family: 'Noto Sans JP', sans-serif;
        margin-top: 30px;
    }
}


.home-image {
    transform: translateX(80%);
    object-fit: cover;
    position: absolute;
    height: 90%;
    width: 30%;
}

.home-container {
    height: 100%;
    width: 80%;
}

.home-container-inner {
    @include flexCenter;

    width: 100%;
    height: 100%;
}



@media (max-width:1000px) {
    .home-image {
        width: 40%;
        transform: translateX(60%);
    }
}



@media (max-width:800px) {

    .home {
        height: auto;
        padding-top: 60px;
    }



    .home-heading-container {
        width: auto;
        height: auto;
        transform: translateX(0);
    }

    .home-container-inner {
        flex-direction: column;
    }

    .home-image {
        width: 400px;
        height: 400px;
        position: static;
        transform: translateX(0);
        margin-top: 50px;
    }
}



@media (min-width:350px) and (max-width:499px) {

    .home-image {
        width: 300px;
        height: 350px;
    }
}

@media (max-width:349px) {
    .home-image {
        width: 280px;
        height: 330px;
    }
}